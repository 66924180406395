// status.js

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ProviderCard from "../components/Status/ProviderCard";

const IndexPage = () => {
  // Import providers data
  const data = useStaticQuery(graphql`
    query {
      allProvidersJson {
        nodes {
          slug
          name
          icon
          description
        }
      }
    }
  `);

  const providers = data.allProvidersJson.nodes;

  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">Website Status Tracker</h1>
      <div className="row">
        {providers.map((provider) => (
          <div key={provider.slug} className="col">
            <ProviderCard
              slug={provider.slug}
              name={provider.name}
              icon={provider.icon}
              description={provider.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndexPage;
