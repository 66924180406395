// client/src/components/Status/ProviderCard.js

import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const ProviderCard = ({ slug, name, icon, description }) => {
  return (
    <div className="card shadow-sm mb-4">
      {/* Display provider icon */}
      <img
        src={icon}
        alt={`${name} icon`}
        className="card-img-top"
        style={{ height: "150px", objectFit: "contain", padding: "20px" }}
      />
      
      {/* Card body with provider details */}
      <div className="card-body">
        <h5 className="card-title">{name}</h5>

        {/* Display provider description or fallback text if not available */}
        <p className="card-text">
          {description || "Description not available for this provider."}
        </p>

        {/* Link to view more details about the provider's status */}
        <Link to={`/status/${slug}/`} className="btn btn-primary">
          View Status
        </Link>
      </div>
    </div>
  );
};

// Prop type validation to ensure the correct props are passed
ProviderCard.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired, // Optional since it might not always be available
};

// Default props in case some fields are missing
ProviderCard.defaultProps = {
  description: "No description provided.",
};

export default ProviderCard;
